import React from "react";
import styles from "./newsletter.module.scss";

import { useFetcher } from "@remix-run/react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Newsletter() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const fetcher = useFetcher();

  const handleBetaTestSignup = async (event) => {
    event.preventDefault();

    try {
      if (!window.grecaptcha) {
        alert("reCAPTCHA is not ready. Please refresh the page.");
        return;
      }

      const token = await executeRecaptcha("newsletter_opt_in");

      if (!token) {
        throw new Error("Failed to generate reCAPTCHA token.");
      }

      const formData = new FormData(event.target);
      formData.append("email", formData.get("email"));
      formData.append("firstName", formData.get("firstName"));
      formData.append("companyName", formData.get("companyName"));
      formData.append("recaptchaToken", token);

      fetcher.submit(formData, {
        method: "post",
        action: "/subscribe-newsletter",
      });
    } catch (error) {
      console.error("Error generating reCAPTCHA token:", error);
      alert("Error validating reCAPTCHA. Please try again.");
    }
  };

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <h2>
          {" "}
          Get monthly product <br /> updates from{" "}
          <span className="text-tertiary"> Headlockr </span>{" "}
        </h2>
        <h4> Sign up for our newsletter </h4>
        <p style={{ maxWidth: "95%" }}>
          {" "}
          And dont worry we wont spam you till death cause in the end this is
          about developing and securing your Strapi.{" "}
        </p>
      </div>
      <div
        className={`${styles.newsletter} col-lg-6 col-md-6 col-sm-12 bg-secondary p-2 rounded-4`}
      >
        {fetcher.state === "idle" && fetcher.data?.success && (
          <div className="alert alert-success mx-auto z-1" role="alert">
            Thank you for signing up for the headlockr newsletter! 🎉 Please
            confirm your subscription via email.
          </div>
        )}
        {fetcher.state === "idle" && fetcher.data?.error && (
          <div className="alert alert-danger mx-auto" role="alert">
            {fetcher.data.error}
          </div>
        )}

        <fetcher.Form
          id="newsletter"
          method="post"
          onSubmit={handleBetaTestSignup}
        >
          <div className="form-group mb-1">
            <input
              className="form-control Input bg-darkblue text-white"
              type="text"
              name="name"
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-group mb-1">
            <input
              type="email"
              name="email"
              placeholder="Enter your company email"
              className="form-control Input bg-darkblue text-white"
            />
          </div>

          <div className="form-group">
            <input
              className="form-control Input bg-darkblue text-white"
              type="text"
              name="companyName"
              placeholder="Company name"
              required
            />
          </div>

          <p
            // font style italic
            className="my-2"
            style={{
              fontStyle: "italic",
            }}
          >
            {" "}
            By filling out this form, you agree to our privacy policy. This form
            is protected by reCAPTCHA and Google’s Privacy Policy and Terms of
            Service apply.{" "}
          </p>
          <button className="btn btn-tertiary" disabled={fetcher.state === "submitting"}>
            {fetcher.state === "submitting" ? (
              <div className="spinner-border spinner-border-sm text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Subscribe"
            )}
          </button>
        </fetcher.Form>
      </div>
    </div>
  );
}
