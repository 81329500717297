import React from "react";
import styles from "./hero.module.scss";
import { Link } from "@remix-run/react";

export default function Cta() {
  return (
    <div className={`w-75 ${styles.cta}`}>
      <span className="badge text-white text-bg-tertiary mb-2">
        Available now
      </span>

      <h1>
        Unlock Ultimate Security for Your  {" "}
        <span className="text-tertiary">Strapi</span> APIs.
      </h1>
      <p className="mt-2">
        {" "}
        The only Plug & Play Multi-Factor Authentication solution built
        exclusively for{" "}
        <a
          href="https://strapi.io"
          className="text-tertiary"
          target="_blank"
          rel="noreferrer noopener"
        >
          Strapi
        </a>
        . Effortless to install. Seamless to scale. Unmatched protection.{" "}
      </p>
      <div className="d-flex gap-1 mt-2">
        <Link to="/#pricing" className="btn btn-tertiary">
          Start your free trial
        </Link>

        <Link to="/#learn-more" className="btn btn-secondary">
          Learn More
        </Link>
      </div>
    </div>
  );
}
