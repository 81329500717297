import type { MetaFunction } from "@remix-run/cloudflare";
import ogImage from "../assets/meta/headlockr-og-image.png";
import logo from "../assets/logo/logo.png";

import Hero from "../components/index/hero/Hero";
import Features from "../components/index/features/Features";
import Usps from "../components/index/usps/Usps";
import Testimonials from "../components/index/testimonials/Testimonials";
import Benefits from "../components/index/benefits/Benefits";
import Pricing from "../components/index/pricing/Pricing";
import Faq from "../components/index/faq/Faq";
import Developers from "../components/index/developers/Developers";

import Section from "../components/utils/Section";
import Video from "../components/index/introduction/Video";
import Newsletter from "../components/index/newsletter/Newsletter";

export const meta: MetaFunction = () => {
  const og = new URL(ogImage, "https://headlockr.io").href;
  const logoUrl = new URL(logo, "https://headlockr.io").href;

  return [
    { title: "HeadLockr - Multi-Factor Authentication for Strapi CMS" },
    {
      property: "og:title",
      content: "HeadLockr - Multi-Factor Authentication for Strapi CMS",
    },
    {
      property: "og:description",
      content:
        "Enhance your Strapi CMS security with HeadLockr, a powerful multi-factor authentication plugin offering robust protection and seamless integration.",
    },
    { property: "og:url", content: "https://www.headlockr.com" },
    {
      property: "og:image",
      content: og,
    },
    {
      name: "description",
      content:
        "Enhance your Strapi CMS security with HeadLockr, a powerful multi-factor authentication plugin. Designed for seamless integration with Strapi, HeadLockr offers robust protection against unauthorized access, credential theft, and compliance risks. Easily configure SMS, TOTP, and email-based verification to safeguard your backend and user data with industry-standard security.",
    },
    { property: "og:type", content: "website" },
    { name: "twitter:card", content: "summary_large_image" },
    {
      name: "twitter:title",
      content: "HeadLockr - Multi-Factor Authentication for Strapi CMS",
    },
    {
      name: "twitter:description",
      content:
        "Enhance your Strapi CMS security with HeadLockr, a powerful multi-factor authentication plugin offering robust protection and seamless integration.",
    },
    {
      name: "twitter:image",
      content: og,
    },
    { name: "twitter:site", content: "@headlockr" },
    {
      "script:ld+json": {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": ["WebPage", "ItemPage", "FAQPage"],
            "@id": "https://headlockr.io/",
            url: "https://headlockr.io/",
            name: "Headlockr - Secure MFA Plugin for Strapi",
            isPartOf: { "@id": "https://headlockr.io/#website" },
            about: { "@id": "https://headlockr.io/#organization" },

            primaryImageOfPage: { "@id": "https://headlockr.io/#primaryimage" },
            image: { "@id": "https://headlockr.io/#primaryimage" },
            thumbnailUrl: og,
            datePublished: "2024-01-01T00:00:00+00:00", // Needs to be dynamic
            dateModified: "2024-11-25T09:00:00+00:00", // Needs to be dynamic
            description:
              "Enhance your Strapi security with Headlockr, a premium MFA plugin. Secure your admin panel with SMS, TOTP, and backup codes.",
            inLanguage: "en-US",
            potentialAction: [
              {
                "@type": "ReadAction",
                target: ["https://headlockr.io/"],
              },
            ],
            video: [
              {
                "@id": "https://headlockr.io/#getting-started",
              },
            ],
            mainEntity: [
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq1",
                name: "What is Headlockr?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "Headlocks is a multifactor authentication plugin built for Strapi. It supports multiple authentication methods, including SMS, email, and TOTP. It's plug and play, easy to use, and highly customizable. Headlockr protects your admin panel and content API from unauthorized access and data breaches. It adds an extra layer of security to your Strapi project, making it more secure and compliant with data protection regulations.",
                },
                position: 1,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq2",
                name: "How do I install Headlockr?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "To install Headlockr, you will need to download the plugin via npm and install it in your Strapi project. In order to be able to download the software you'll need a valid license to do so. After installation you can configure the plugin settings and enable it for the desired authentication methods. We do have dedicated documentation https://docs.headlockr.io/  where you can follow the installation guide step by step.",
                },
                position: 2,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq3",
                name: "What is MFA?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "MFA works by requiring two or more of the following authentication methods: something you know (like a password), something you have (like a phone or hardware token), or something you are (like a fingerprint).",
                },
                position: 3,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq4",
                name: "How does MFA work?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "MFA stands for Multi-Factor Authentication. It is a security feature that requires more than one method of authentication from independent categories of credentials to verify the user's identity for a login or other transaction.",
                },
                position: 4,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq5",
                name: "What are the benefits of MFA?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "MFA provides an additional layer of security to protect your accounts and data from unauthorized access. It reduces the risk of unauthorized access, credential theft, and compliance risks.",
                },
                position: 5,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq6",
                name: "How do I setup MFA?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "To set up MFA, you need to enable it in the settings of your account or application. You can choose from various authentication methods like SMS, TOTP, and backup codes.",
                },
                position: 6,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq7",
                name: "What if I lose access to my MFA device?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "If you lose access to your MFA device, you can use backup codes to regain access to your account. Make sure to store your backup codes in a safe place. In case you lose your backup codes, you can contact your administrator or customer support.",
                },
                position: 7,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq8",
                name: "What is the difference between MFA and 2FA?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "MFA stands for Multi-Factor Authentication, which requires two or more methods of authentication from independent categories of credentials. 2FA stands for Two-Factor Authentication, which requires two methods of authentication from two different categories of credentials.",
                },
                position: 8,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq9",
                name: "Is MFA secure?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "MFA is a secure way to protect your accounts and data from unauthorized access. It adds an extra layer of security by requiring multiple methods of authentication. While no security measure is foolproof or 100% secure, MFA significantly reduces the risk of unauthorized access, credential theft, and compliance risks.",
                },
                position: 9,
              },
              {
                "@type": "Question",
                "@id": "https://headlockr.io/#faq10",
                name: "Can MFA be bypassed?",
                inLanguage: "en-US",
                acceptedAnswer: {
                  "@type": "Answer",
                  text: "MFA can be bypassed in some cases, but it significantly reduces the risk of unauthorized access and credential theft. It is not immune to attacks though. Some sophisticated attackers may be able to bypass MFA through social engineering, phishing, or other methods. It is important to remain vigilant and follow best practices for securing your accounts. To enhance security, you can use additional security measures like strong passwords, regular software updates, and security monitoring.",
                },
                position: 10,
              },
            ],
          },
          {
            "@id": "https://headlockr.io/#website",
            "@type": "WebSite",
            url: "https://headlockr.io/",
            name: "Headlockr",
            description: "Secure your Strapi CMS with Headlockr",
            publisher: { "@id": "https://headlockr.io/#organization" },
            potentialAction: [
              {
                "@type": "BuyAction",
                target: "https://headlockr.io/#pricing",
              },
            ],
            inLanguage: "en-US",
            copyrightHolder: {
              "@id": "https://headlockr.io/#organization",
            },
          },
          {
            "@type": "ImageObject",
            "@id": "https://headlockr.io/#primaryimage",
            url: logoUrl,
            contentUrl: logoUrl,
            caption: "Headlockr Logo",
            width: 800, // Verify this
            height: 800, // Verify this
          },
          {
            "@type": ["Product", "SoftwareApplication"],
            "@id": "https://headlockr.io/#software",
            name: "Headlockr - Secure MFA Plugin for Strapi",
            description:
              "Enhance your Strapi security with Headlockr, a premium MFA plugin. Secure your admin panel with SMS, TOTP, and backup codes.",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              ratingCount: "132",
            },
            image: { "@id": "https://headlockr.io/#primaryimage" },
            applicationCategory: "SecurityApplication",
            applicationSuite: "Strapi",
            operatingSystem: "Linux, Windows, macOS",
            memoryRequirements: "4GB+",
            storageRequirements: "32GB+",
            softwareVersion: "1.0.0",
            softwareRequirements: "Node.js 16+",
            isAccessibleForFree: false,
            mainEntityOfPage: {
              "@id": "https://headlockr.io/",
            },
            featureList: [
              "Multifactor authentication for Strapi",
              "Supports SMS, TOTP, and backup codes",
              "Native admin panel integration",
            ],
            countriesSupported:
              "AF, AX, AL, DZ, AS, AD, AO, AI, AQ, AG, AR, AM, AW, AU, AT, AZ, BS, BH, BD, BB, BY, BE, BZ, BJ, BM, BT, BO, BA, BW, BV, BR, IO, BN, BG, BF, BI, KH, CM, CA, CV, KY, CF, TD, CL, CN, CX, CC, CO, KM, CG, CD, CK, CR, CI, HR, CU, CY, CZ, DK, DJ, DM, DO, EC, EG, SV, GQ, ER, EE, ET, FK, FO, FJ, FI, FR, GF, PF, TF, GA, GM, GE, DE, GH, GI, GR, GL, GD, GP, GU, GT, GG, GN, GW, GY, HT, HM, VA, HN, HK, HU, IS, IN, ID, IR, IQ, IE, IM, IL, IT, JM, JP, JE, JO, KZ, KE, KI, KP, KR, KW, KG, LA, LV, LB, LS, LR, LY, LI, LT, LU, MO, MG, MW, MY, MV, ML, MT, MH, MQ, MR, MU, YT, MX, FM, MD, MC, MN, ME, MS, MA, MZ, MM, NA, NR, NP, NL, NC, NZ, NI, NE, NG, NU, NF, MP, NO, OM, PK, PW, PS, PA, PG, PY, PE, PH, PN, PL, PT, PR, QA, RE, RO, RU, RW, BL, SH, KN, LC, MF, PM, VC, WS, SM, ST, SA, SN, RS, SC, SL, SG, SX, SK, SI, SB, SO, ZA, GS, SS, ES, LK, SD, SR, SJ, SE, CH, SY, TW, TJ, TZ, TH, TL, TG, TK, TO, TT, TN, TR, TM, TC, TV, UG, UA, AE, GB, US, UM, UY, UZ, VU, VE, VN, VG, VI, WF, EH, YE, ZM, ZW",
            author: {
              "@id": "https://headlockr.io/#organization",
            },
            copyrightHolder: {
              "@id": "https://headlockr.io/#organization",
            },
            maintainer: {
              "@id": "https://headlockr.io/#organization",
            },
            acquireLicensePage: "https://headlockr.io/#pricing",
            license: "https://headlockr.io/license",
            releaseNotes: "https://headlockr.io/changelog",
            softwareHelp: {
              "@type": "CreativeWork",
              url: "https://docs.headlockr.io",
              name: "Headlockr Documentation",
              description:
                "Comprehensive documentation for Headlockr, including installation, configuration, and usage guidelines.",
            },
            offers: [
              {
                "@type": "Offer",
                name: "Individual License",
                price: 9.99,
                priceCurrency: "EUR",
                priceValidUntil: "2099-12-31T23:59:59+01:00",
                availability: "https://schema.org/InStock",
                description: "License for 1 Strapi project",
                url: "https://headlockr.io/#pricing",
              },
              {
                "@type": "Offer",
                name: "Developer License",
                price: 22.99,
                priceCurrency: "EUR",
                priceValidUntil: "2099-12-31T23:59:59+01:00",
                availability: "https://schema.org/InStock",
                description: "License for 3 Strapi projects",
                url: "https://headlockr.io/#pricing",
              },
              {
                "@type": "Offer",
                name: "Agency License",
                price: 29.99,
                priceCurrency: "EUR",
                priceValidUntil: "2099-12-31T23:59:59+01:00",
                availability: "https://schema.org/InStock",
                description: "License for 10 Strapi projects",
                url: "https://headlockr.io/#pricing",
              },
            ],
            keywords: [
              "Strapi",
              "plugin",
              "MFA",
              "security",
              "authentication",
              "multifactor",
            ],
          },
          {
            "@type": "VideoObject",
            "@id": "https://headlockr.io/#getting-started",
            name: "Introduction to Headlockr",
            description:
              "A quick overview of how Headlockr enhances Strapi CMS security.",
            thumbnailUrl: og,
            contentUrl: "https://youtu.be/VXEHOdOW1QU",
            uploadDate: "2024-12-24T10:47:08Z",
            duration: "PT2M30S",
            isPartOf: { "@id": "https://headlockr.io/" },
            isFamilyFriendly: true,
            width: 1920,
            height: 1080,
            inLanguage: "en-US",
          },
          {
            "@type": "OnlineBusiness",
            "@id": "https://headlockr.io/#organization",
            name: "Headlockr",
            legalName: "STRHUB B.V.",
            identifier: {
              // KVK
              "@type": "PropertyValue",
              propertyID: "KVK",
              value: "96493658",
            },
            description:
              "A company specializing in building secure software solutions for developers.",
            url: "https://headlockr.io",
            vatId: "NL867635307B01",
            telephone: "+31-20-1234567",
            email: "info@headlockr.io",
            logo: logoUrl,
            foundingDate: "2024-01-01",
            foundingLocation: {
              "@type": "Place",
              name: "Goirle, Netherlands",
            },
            founder: {
              "@type": "Person",
              name: "Kevin Vugts",
              sameAs: "https://www.linkedin.com/in/kevin-v-b3b717131/",
            },
            location: {
              "@type": "Place",
              name: "Goirle, Netherlands",
            },
            address: {
              "@type": "PostalAddress",
              streetAddress: "Parallelweg 14",
              addressLocality: "Goirle",
              addressRegion: "Noord-Brabant",
              postalCode: "5051HG",
              addressCountry: "NL",
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+31-20-1234567",
              contactType: "customer support",
              email: "info@headlockr.io",
              areaServed: "Worldwide",
            },
            sameAs: [
              "https://twitter.com/headlockr",
              "https://linkedin.com/company/headlockr",
              "https://github.com/headlockr",
            ],
            slogan: "Secure your Strapi CMS with Headlockr",
          },
        ],
      },
    },
  ];
};

export default function Index() {
  return (
    <div className="bg-primary">
      <Hero />
      <Section id="video" className="overflow-x-hidden">
        <Video />
      </Section>

      <Section id="learn-more" className="overflow-x-hidden">
        <Features />
      </Section>
      <Section id="usps">
        <Usps />
      </Section>
      <div
        className="bg-secondary position-relative mt-10 slanted-bg"
        style={{
          width: "100vw",
          left: "50%",
          right: "50%",
          marginLeft: "-50vw",
        }}
      >
        <Section id="testimonials" className="px-4 py-5">
          <Testimonials />
        </Section>
      </div>
      <Section id="benefits">
        <Benefits />
      </Section>

      <Section id="pricing">
        <Pricing />
      </Section>

      <Section id="faq">
        <Faq />
      </Section>
      <Section id="installation">
        <Developers licenseKey="enter-your-license-key-here" />
      </Section>

      <Newsletter />
    </div>
  );
}
