import React from "react";
import styles from "./introduction.module.scss";

import ManWithComputer from "../../../assets/introduction/headlockr-man-behind-computer.png";

export default function Video() {
  return (
    <div className="row align-items-end position-relative">
      <div className="col-lg-5 offset-lg-2">
        <div className="ratio ratio-16x9 mt-4" id="getting-started">
          <iframe
            className="rounded-4"
            src="https://www.youtube.com/embed/56NUkJgDwqk?si=ty7_F15EDwtMir0n"
            title="Headlockr in 1 minute - Why Your Strapi Admin Needs MFA"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="col-lg-5">
        <img
          src={ManWithComputer}
          alt="ManWithComputer"
          className={styles.manWithComputer}
          style={{
            width: 300,
          }}
        />
      </div>
    </div>
  );
}
